import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useTrans } from 'hooks'
import { getFormFieldStatus } from 'utils/safe'
import { useMotivazioniContattoQuery, useCreateUtenteContattoMutation } from 'gql/graphql'
import { useRouter } from 'next/dist/client/router'
import { Alert } from 'components/atoms/Icons'
import {
  FormField,
  FormInput,
  FormLayout,
  FormSelect,
  FormTextarea,
  FormCheckbox,
  Button,
  NotificationBar,
} from 'components/atoms'
import Link from 'next/link'
import { useSelector } from 'react-redux'
import { executeIfInitialized, trackConnectifContact } from 'utils/connectif'

interface Props {
  className?: string
}

const FormContatti = (props: Props) => {
  const { className = '' } = props

  const [motivazioni, setMotivazioni] = useState([])
  const [error, setError] = useState<string | undefined | null>()

  const t = useTrans()
  const router = useRouter()
  const { data: dataMotivazioniContatto } = useMotivazioniContattoQuery()
  const [createUtenteContatto] = useCreateUtenteContattoMutation()

  const { pagine } = useSelector((state) => state.pagine)
  const privacy_policy = pagine?.edges.find((item) => item.node.chiave === 'privacy-policy')?.node

  const validationSchema = yup.object().shape({
    nome: yup.string().required(t('Campo obbligatorio')),
    cognome: yup.string().required(t('Campo obbligatorio')),
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .required(t('Campo obbligatorio')),
    motivo: yup.string().required(t('Campo obbligatorio')),
    messaggio: yup.string().required(t('Campo obbligatorio')),
    privacy: yup.boolean().required().oneOf([true], t('Campo obbligatorio')),
  })

  const initialValues = {
    nome: '',
    cognome: '',
    email: '',
    motivo: '',
    messaggio: '',
    privacy: false,
  }

  useEffect(() => {
    if (dataMotivazioniContatto?.motivazioniContatto) {
      setMotivazioni(dataMotivazioniContatto.motivazioniContatto)
    }
  }, [dataMotivazioniContatto])

  return (
    <div className={`form-contatti${className ? ` ${className}` : ''}`}>
      <Formik
        validateOnBlur
        validateOnChange
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          createUtenteContatto({
            variables: {
              input: {
                nome: values.nome,
                cognome: values.cognome,
                email: values.email,
                messaggio: values.messaggio,
                motivazione: values.motivo,
              },
            },
          })
            .then((res) => {
              setError(null)
              //tracciamento Connectif contatti
              //executeIfInitialized(trackConnectifContact, values)
              router.push('/contattaci/thank-you')
            })
            .catch((err) => {
              setError(t("Errore nell'invio dei dati, riprova più tardi"))
            })
        }}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          isValid,
          isSubmitting,
        }) => (
          <Form>
            {error && (
              <NotificationBar
                label={error}
                variant="alert"
                icon={<Alert />}
                className="form-login__error"
              />
            )}
            <FormLayout>
              <FormField
                label={`${t('Nome')}*`}
                status={getFormFieldStatus({ touched, errors }, 'nome')}
                errorMessage={errors?.nome}
              >
                <FormInput
                  value={values?.nome}
                  placeholder="Nome"
                  onBlur={handleBlur('nome')}
                  onChange={handleChange('nome')}
                />
              </FormField>
              <FormField
                label={`${t('Cognome')}*`}
                status={getFormFieldStatus({ touched, errors }, 'cognome')}
                errorMessage={errors?.cognome}
              >
                <FormInput
                  value={values?.cognome}
                  placeholder="Cognome"
                  onBlur={handleBlur('cognome')}
                  onChange={handleChange('cognome')}
                />
              </FormField>
              <FormField
                label={`${t('La tua email')}*`}
                layout="full"
                status={getFormFieldStatus({ touched, errors }, 'email')}
                errorMessage={errors?.email}
              >
                <FormInput
                  type="email"
                  placeholder="Email"
                  value={values?.email}
                  onBlur={handleBlur('email')}
                  onChange={handleChange('email')}
                />
              </FormField>
              <FormField
                label={`${t('Motivo della richiesta')}*`}
                layout="full"
                status={getFormFieldStatus({ touched, errors }, 'motivo')}
                errorMessage={errors?.motivo}
              >
                <FormSelect
                  id="form-contattaci__motivo"
                  options={motivazioni.map((item) => ({
                    value: item.chiave,
                    label: item.nome,
                  }))}
                  value={values?.motivo}
                  placeholder="Seleziona il motivo della richiesta"
                  onBlur={handleBlur('motivo')}
                  onChange={(selectedOption) => setFieldValue('motivo', selectedOption.value, true)}
                />
              </FormField>
              <FormField
                label={`${t('Motivo della richiesta')}*`}
                layout="full"
                status={getFormFieldStatus({ touched, errors }, 'motivo')}
                errorMessage={errors?.motivo}
              >
                <FormTextarea
                  value={values?.messaggio}
                  placeholder="Scrivi il tuo messaggio"
                  onBlur={handleBlur('messaggio')}
                  onChange={handleChange('messaggio')}
                />
              </FormField>
              <FormField
                layout="full"
                status={getFormFieldStatus({ touched, errors }, 'privacy')}
                errorMessage={errors?.privacy}
              >
                <div className="container-newsletter">
                  <FormCheckbox
                    id="form-contattaci__privacy"
                    label={t('Ho letto e accetto')}
                    className="newsletter-form__privacy"
                    checked={values?.privacy}
                    onBlur={handleBlur('privacy')}
                    onChange={handleChange('privacy')}
                  />
                  <div className="form-checkbox__label privacy">
                    {' '}
                    <Link legacyBehavior href={privacy_policy.url}>
                      <a target="_blank">{t('l’informativa sulla privacy')}</a>
                    </Link>
                  </div>
                </div>
              </FormField>
            </FormLayout>
            <div className="form-contatti__cta-box">
              <Button
                type="submit"
                label={t('Invia richiesta')}
                disabled={Object.keys(touched).length === 0 || !isValid || isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default FormContatti
