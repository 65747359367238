import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Alert({ className }: Props) {
  return (
    <IconBase name="alert" w={24} className={className}>
      <path d="M12 5.99L19.53 19H4.47L12 5.99ZM12 2L1 21H23L12 2ZM13 16H11V18H13V16ZM13 10H11V14H13V10Z" stroke="none"/>
    </IconBase>
  )
}

export default Alert
